import { Grid, Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import CustomButton from "./../../../ui/button/CustomButton";
import RoyMorganLogoImg from "../../../../assets/images/RoyMorgan.svg";

import ExstingRes from "../../../research/RRight/exstingRes/ExstingRes";
import EmptyList from "../../../ui/emptyList/EmptyList";
import RTHeader from "./RTHeader";
import RTList from "./RTList";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.secondary.main,
      marginBottom: "20px",
      fontWeight: 400,
      fontSize: "28px",
    },
    banner: {
      backgroundColor: "#fbe8ed",
      border: "1px solid #d82154",
      padding: "25px",
      borderRadius: "10px"
    },
    h1: {
      color: theme.palette.secondary.main,
      fontWeight: 600,
      paddingBottom: "5px",
      fontSize: "22px",
    },
    h2: {
      color: theme.palette.secondary.main,
      fontWeight: 200,
      fontSize: "21px",
    },
    p: {
      paddingTop: "10px",
      color: "#466682",
      fontWeight: 200,
      fontSize: "18px",
    },
    img: {
      display: "block",
      margin: "auto",
      marginTop: "10px",
      width: "80%",
      maxWidth: "15em"
    }
  };
});

const Research = ({ td, callApi }) => {
  const classes = useStyles();
  const resData = useSelector((state) => state.project?.project?.researches);
  const clientId = useSelector((state) => state.client?.clientId?.id);

  const loc = useNavigate();
  const search = useLocation();
  const addResearch = () => {
    loc(`/research/roy-morgan?source=${search.pathname}&client=${clientId}&project=${td.projectId}`);
  }
  
  
  const [checkArr, setCheckArr] = useState([]);
  useEffect(() => {
    if (td && td.data && td.data.selectedResearch) {
      setCheckArr(() => td.data.selectedResearch);
    } else if (resData && resData.length) {
      setCheckArr(() =>
        resData.map((a) => ({
          checked: false,
          name: a.name,
          type: a.researchType,
          uuid: a.uuid,
        }))
      );
    }
  }, [td, resData]);
  const handleSelect = (c, uuid, name, type) => {
    const data = [...checkArr];
    const findIdx = data.findIndex((a) => a.uuid === uuid);
    if (findIdx !== -1) {
      data[findIdx].checked = c;
    } else {
      data.push({
        checked: c,
        name: name,
        type: type,
        uuid: uuid,
      });
    }
    setCheckArr(data);
    callApi({ selectedResearch: data });
  };
  return (
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className={classes.title}>Research</div>

        <Stack className={classes.banner} style={{ marginBottom: "50px"}}direction="row" gap="20px" mb={1} mt={2}>
          <Box flex="0.7">
            <div className={classes.h1}>Roy Morgan single-source market intelligence</div>

            <div className={classes.h2}>Exclusive to Brand Strategy</div>

            <div className={classes.p}>
              Integrate research into your brand strategy in real time. Select between <strong>demographics, messaging, media, and location</strong> to back your strategy. From $500 per project.
            </div>

            <CustomButton
                styled={{
                  backgroundColor: "rgb(212, 8, 65)",
                  marginTop: "20px"
                }}
                name="Review Categories"
                size="large"
                onclick={addResearch}
            />

          </Box>
          <Box flex="0.3">
            <img
              className={classes.img}
              src={RoyMorganLogoImg}
              alt={RoyMorganLogoImg}
            />
          </Box>
        </Stack>

      </Grid>
      <RTHeader />
      {resData && resData.length ? (
        resData.map((a) => (
          <RTList
            {...a}
            checked={checkArr.find((e) => e.uuid === a.uuid)?.checked || false}
            handleSelect={(e) =>
              handleSelect(e, a.uuid, a.name, a.researchTypeF)
            }
          />
        ))
      ) : (
        <EmptyList
          color="#D40841"
          text="Add research to your project which help validate your project and recommendations."
        />
      )}
      <ExstingRes />
    </Grid>
  );
};

export default Research;
