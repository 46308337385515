import { Box, Dialog, DialogContent, Grid, Slider, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { nFormatter } from "../../../../utils/FormatNumber";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  modal: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "rgba(255,255,255,0.4) !important",
  },
  paperClass2: {
    backgroundColor: `#F5F5F5 !important`,
  },
  dialogContent: {
    height: "716px",
    padding: "0 !important",
    background: "white",
  },
  outerBox: {
    display: "flex",
    flexDirection: "column",
    height: "90px",
    width: "90px",
    margin: "auto",
    padding: "10px",
    textAlign: "center",
    background: "#2c4590",
    color: "white",
    justifyContent: "space-around",
  },
  light: {
    background: "white",
    color: "#2c4590",
  },
}));

const DetailsPersona = (props) => {
  const classes = useStyles();
  const data = {
    labels: [],
    datasets: [
      {
        label: "Values",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };
  if (props.valueSegments && props.valueSegments.length) {
    data.labels = props.valueSegments.map((a) => a.name || "");
    data.datasets[0].data = props.valueSegments.map((a) => a.value || 0);
  }
  return (
    <Dialog
      className={classes.modal}
      classes={{
        paper: classes.paperClass2,
      }}
      open={props.open}
      fullWidth={true}
      onClose={props.onClose}
      maxWidth={"xl"}
    >
      <DialogContent className={classes.dialogContent}>
        <Stack gap="10px">
          <img
            src={`/static/personas/${props.code}/banner.png`}
            alt="banner"
            width="100%"
            height="auto"
          />

          <Grid container mt={1} spacing={2} p={2}>
            <Grid item container lg={6} md={6} sm={6} xs={6} spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Box fontSize="18px" color="secondary.main" mb={2}>
                  {props.name} (ID : {props.code}){" "}
                </Box>
                <Stack gap="8px">
                  <Box color="#888">
                    <i
                      aria-label="People icon"
                      className="fas fa-users"
                      style={{ color: "black" }}
                    ></i>{" "}
                    Population: <b>{nFormatter(props.population || 0)}</b>
                  </Box>
                  <Box color="#888">
                    <i
                      aria-label="Globe icon"
                      className="fas fa-globe-africa"
                      style={{
                        marginLeft: "2px",
                        marginRight: "1px",
                        color: "black",
                      }}
                    ></i>
                    {"  "}
                    Population percentage: <b>{props.percentage || 0}%</b>
                  </Box>
                  <Box color="#888" mb={2}>
                    <i
                      aria-label="Dollars icon"
                      className="fas fa-dollar-sign"
                      style={{
                        marginLeft: "4px",
                        marginRight: "4px",
                        color: "black",
                      }}
                    ></i>{" "}
                    Average household income:{" "}
                    <b>${nFormatter(props.income || 0)}</b>
                  </Box>
                  {props.descriptions && props.descriptions.length
                    ? props.descriptions
                        .slice(0, Math.round(props.descriptions.length / 2))
                        .map((a) => <Box key={a}>{a}</Box>)
                    : null}
                </Stack>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Stack gap="8px">
                  {props.descriptions && props.descriptions.length
                    ? props.descriptions
                        .slice(Math.round(props.descriptions.length / 2))
                        .map((a) => <Box key={a}>{a}</Box>)
                    : null}
                </Stack>
              </Grid>
            </Grid>
            <Grid container item lg={6} md={6} sm={6} xs={6} spacing={2}>
              <Grid item lg={9} md={9} sm={9} xs={9}>
                <Box
                  textAlign="center"
                  fontSize="18px"
                  color="secondary.main"
                  mb={2}
                >
                  THOUGHT CLOUD
                </Box>
                <img
                  src={`/static/personas/${props.code}/thought-cloud.png`}
                  alt="thougth img"
                  width="100%"
                  height="auto"
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Box
                  textAlign="center"
                  fontSize="18px"
                  color="secondary.main"
                  mb={2}
                >
                  LIKES
                </Box>
                <Stack justifyContent="center">
                  {props.likes && props.likes.length
                    ? props.likes.map((a, idx) => (
                        <Box
                          className={`${classes.outerBox} ${
                            (idx + 1) % 2 == 0 && classes.light
                          }`}
                          key={a.name}
                        >
                          <Box fontSize="22px">
                            <i className={`fa-${a.iconKey} fas`}></i>
                          </Box>
                          <Box> {a.name} </Box>
                        </Box>
                      ))
                    : null}{" "}
                </Stack>
              </Grid>
              <Grid item lg={9} md={9} sm={9} xs={9}>
                <Box
                  textAlign="center"
                  fontSize="18px"
                  color="secondary.main"
                  mb={0}
                >
                  VALUE SEGMENTS
                </Box>
                <Box width="70%" margin="auto">
                  <Radar data={data} options={options} />
                </Box>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Box
                  textAlign="center"
                  fontSize="18px"
                  color="secondary.main"
                  mb={2}
                >
                  PROGRESSIVENESS
                </Box>
                <Slider
                  disabled
                  defaultValue={props.progressiveness || 0}
                  aria-label="Disabled slider"
                />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DetailsPersona;
